@import './../../base'

.server-time
  @include flex
  flex-direction: column
  align-items: center
  justify-content: center
  line-height: 16px
  color: rgba(var(--primaryTxtColor))
  margin-right: 16px
