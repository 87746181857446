@import './../../base'

.top-header-search
  @include flex
  min-width: 302px

  .ant-input-group
    position: relative
  .ant-input
    background: var(--header_searchBg)
    border-color: var(--header_searchBorder)
    border-right-color: var(--header_tabShadow)!important
    color: #fff
    padding-right: 28px
    box-shadow: 0 1px rgba(255,255,255,.1)
    border-radius: 2px 0 0 2px
  .ant-input-search .ant-input:hover, .ant-input-search .ant-input:focus
    background: var(--header_searchHoverBg)
    border-color: var(--header_searchBorder)
  .ant-input-search .ant-input-group-addon
    position: absolute
    right: 4px
    top: 0
    z-index: 1
    width: 20px
    height: 100%
    left: unset!important
    background: transparent
    .ant-btn
      background: transparent
      border: 0
      padding: 0
      width: 20px
      height: 100%
      @include flex

      .anticon-search
        color: #fff

  .ant-radio-group.ant-radio-group-outline
    border: 1px solid var(--header_searchBorder)
    background: var(--header_tabBg)
    @include flex
    height: 22px
    border-top-right-radius: 2px
    border-bottom-right-radius: 2px
    box-shadow: inset 0 1px var(--header_tabShadow), 0 1px rgba(255,255,255,.1)
    .ant-radio-wrapper
      @include flex
      padding: 0 6px
      margin: 0
      height: 100%
      color: rgba(var(--primaryTxtColor),.7)
      font-size: 11px!important
      min-width: 66px
      span
        line-height: 12px
      &:first-of-type
        border-right: 1px solid var(--header_searchBorder)
      &:last-of-type
        border-left: 1px solid var(--header_tabShadow)
      &.ant-radio-wrapper-checked
        background: var(--header_tabActiveBg)
        color: #fff
      .ant-radio
        top: 0
        &.ant-radio-checked:after
          content: none
      .ant-radio-inner
        width: 14px
        height: 14px
        border-color: #000!important
        background-color: var(--header_radioBtnBg)
        box-shadow: 0 1px rgba(255,255,255,.1)
        &:after
          width: 14px
          height: 14px
          margin-top: -7px
          margin-left: -7px
          background-color: var(--primaryColor)

.top-search-by-player, .top-search-by-bet
  padding: 0
  top: 36px
  max-height: 650px
  .ant-modal-content
    height: 100%
    max-height: 650px
  .ant-modal-close
    top: 3px !important

  .ant-modal-body
    padding: 24px 0 0

    #players-page
      min-width: 98%
      padding: 5px
      border-top: 1px solid #e4e8eb
      .ant-card-bordered
        border: 0
        border-top: 1px solid #e4e8eb
      .ant-table-header > table
        border-top: 0
  .ant-table-pagination.ant-pagination
    padding: 4px 0 0!important

  .ant-table-body
    max-height: 550px!important
.top-search-by-player

  width: 770px !important

.top-search-by-bet
  .ant-table-wrapper
    border-top: 1px solid #e4e8eb
    margin: 0!important
    max-width: 100%!important
    padding: 5px
  .ant-table-fixed-header
    height: calc(100% - 27px)!important
