@import '../../base'

.page-main-layout.ant-layout
  background: #fff
  // min-width: 920px

  .page-content
    padding: 10px 20px 20px
    height: calc(100% - 43px)
    overflow: auto
    > section
      height: 100%

  .page-header
    background: var(--header_bg)
    @include flex
    justify-content: space-between
    border-bottom: 1px solid #263646
    height: 43px
    padding: 0 20px
    overflow-x: auto
    &::-webkit-scrollbar
      height: 2px
    &::-webkit-scrollbar-thumb, .rc-virtual-list-scrollbar-thumb
      border-width: 1px
    > div
      min-width: 920px
      @include flex
      position: relative
      height: 100%
      width: 100%
      justify-content: space-between

      .page-header-middle-content
        @include flex
        height: 100%
        align-items: center
        position: absolute
        left: 50%
        transform: translateX(-50%)

@media screen and (max-width: 1360px)
  .page-header
    > div
      .page-header-middle-content
        position: unset !important
        transform: unset !important

.header-user
  background: var(--header_userBlockBg)
  border-radius: 3px
  @include flex
  height: 30px
  padding: 2px 4px 2px 8px
  color: #fff
  > svg
    margin-right: 8px
    path
      fill: var(--primaryColor)
  > *
    line-height: 14px

  > button.ant-btn:not(:disabled):not(.ant-btn-disabled)
    @include bold
    margin-left: 10px
    background: var(--primaryColor)
    color: var(--btn_color)!important
    &:hover
      background: var(--btn_primaryHoverBg)
    &:active
      background: var(--btn_primaryActiveBg)

  .ant-select
    width: 72px
    margin-left: 10px
    height: 15px
    img
      margin-right: 8px
    .ant-select-arrow
      color: #fff
    .ant-select-selector:not(.ant-select-allow-clear)
      border-color: #C0CCD5!important
      border-style: dotted

      input, .ant-select-selection-item
        height: 100%!important
        @include flex
        color: #fff!important
        margin: 0

    .ant-select-selector
      background: transparent
      border-radius: 0
      border-top: 0
      border-bottom: 0
      outline: none!important
      box-shadow: none!important

  .anticon-logout
    @include flex
    svg
      font-size: 14px

.header-languages-list-item .ant-select-item-option-content
  @include flex
  margin: 0!important
  img
    margin-right: 8px

.ant-tabs-tabpane
  height: 100%

.tabs-container.ant-tabs-card
  > .ant-tabs-nav
    background: #ffffff linear-gradient(to bottom, #ffffff 0%,#f9f9fb 3%,#f9f9fb 48%,#f7f7f7 55%,#f7f7f9 66%,#f5f5f5 100%)
    margin: 0!important
    .ant-tabs-tab.ant-tabs-tab-active
      background: #fff
      border-bottom-color: #fff
      .ant-tabs-tab-btn
        color: #ec463a
    .ant-tabs-tab:hover, .ant-tabs-tab-btn:active
      color: #ec463a
    .ant-tabs-tab:not(.ant-tabs-tab-active):hover
      cursor: pointer
  &.ant-tabs-top > .ant-tabs-nav::before
    border-color: #dfe4e8
  > .ant-tabs-nav .ant-tabs-tab, > div > .ant-tabs-nav .ant-tabs-tab
    border-top: 0
    border-left: 0
    border-right: 1px solid
    border-color: #dfe4e8
    box-shadow: 1px 0 0 #fff
    border-radius: 0!important
    margin: 0!important
    background: transparent
    line-height: 12px
    padding: 8px 16px

  .ant-tabs-content
    padding: 6px
    height: 100%

.are-you-sure-modal .ant-modal-body
  min-height: 70px
  padding: 20px
  @include flex
  flex-direction: column
  justify-content: center
  align-items: center

::-webkit-scrollbar, .rc-virtual-list-scrollbar-thumb
  width: 8px
  height: 8px

::-webkit-scrollbar-thumb, .rc-virtual-list-scrollbar-thumb
  border: 2px solid rgba(0, 0, 0, 0)!important
  border-radius: 20px!important
  background-clip: padding-box!important
  background-color: #7ebbe0!important

/*fixed multi select*/
.ant-select-multiple .ant-select-selection-wrap
  align-self: center